import { FETCH_FILE, FETCH_RESOURCES, FILE_CONTENT } from '@/store/actions.type';
import { SET_RESOURCES } from '@/store/mutations.type';
import ApiService from '@/common/api.service';
import { getToken } from '@/common/jwt.service';
import { API_URL } from '@/common/config';

const state = {
  resources: {
    resources: [],
  },
};

const getters = {
  resources: () => state.resources.resources,
};

const actions = {
  [FETCH_RESOURCES](context, params = {}) {
    return new Promise((resolve, reject) => {
      ApiService.query('front/v1/resources', params)
        .then(({ data }) => {
          context.commit(SET_RESOURCES, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [FETCH_FILE](context, params = {}) {
    const { session, resourceId } = params;
    return ApiService.downloadQuery(`front/v1/resources/download/${session}/${resourceId}`)
      .then((data) => {
        const BlobFile = new Blob([data.data]);
        const fileURL = window.URL.createObjectURL(BlobFile);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', params.fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  },
  [FILE_CONTENT](context, params = {}) {
    const {
      session,
      resourceId,
      fileType,
      fileName,
    } = params;
    if (['SHAREPOINT_PPT', 'SHAREPOINT_EXCEL', 'SHAREPOINT_WORD'].includes(fileType)) {
      return (`${API_URL}/api/front/v1/resources/download/${session}/${resourceId}?token=${getToken()}`);
    }
    return ApiService.downloadQuery(`front/v1/resources/download/${session}/${resourceId}`)
      .then((data) => {
        let type = 'application/pdf';
        if (fileType === 'VIDEO') {
          type = 'video/mp4';
        } else if (fileType === 'IMAGE') {
          type = 'image/jpg';
        }
        const BlobFile = new File([data.data], fileName, { type });
        return URL.createObjectURL(BlobFile);
      });
  },
};

const mutations = {
  [SET_RESOURCES](states, { data }) {
    states.resources = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
